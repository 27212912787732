import Vue from 'vue'
import VueRouter from 'vue-router'

import MainContauner from '@/components/MainContauner';

Vue.use(VueRouter)

const constantRoutes = [{
	path: '',
	component: MainContauner,
	redirect: 'index',
	children: [{
			path: 'index',
			component: () => import('@/views/index'),
			name: 'Index',
			meta: {
				title: '首页',
			}
		},
		{
			path: '/products',
			component: () => import('@/views/products'),
			meta: {
				title: '产品功能',
			}
		},
		{
			path: '/productsMore',
			component: () => import('@/views/productsMore'),
			meta: {
				title: '产品功能',
			}
		},
		{
			path: '/cases',
			component: () => import('@/views/cases'),
			meta: {
				title: '客户案例',
			}
		},
		{
			path: '/trial',
			component: () => import('@/views/trial'),
			meta: {
				title: '立即试用',
			}
		},
		{
			path: '/about',
			component: () => import('@/views/about'),
			meta: {
				title: '关于我们',
			}
		},
		{
			path: '/help',
			component: () => import('@/views/help'),
			meta: {
				title: '帮助中心',
			}
		},
		{
			path: '/news',
			component: () => import('@/views/news'),
			meta: {
				title: '新闻咨询',
			}
		},
		{
			path: '/newsDetails',
			component: () => import('@/views/newsDetails'),
			meta: {
				title: '新闻咨询-详情',
			}
		},
		
	]
}]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
	return routerReplace.call(this, location).catch(err => err)
}

const createRouter = () =>
	new VueRouter({
		mode: 'history', // 去掉url中的#
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition;
			} else {
				return {
					x: 0,
					y: 0
				};
			}
		},
		routes: constantRoutes,
	})
const router = createRouter()
// 重置路由
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher
}
export default router