<template>
	<div class="header_box">
		<div class="header">
			<div class="logo">
				<img src="../../assets/images/logo.png" alt="" />
			</div>
			<div class="header_rt">
				<div class="rt_btn">
					<a href="tel:4000360170">联系我们</a>
				</div>
				<div class="rt_menu_icon" @click="toggleMenu()">
					<img v-if="!menuVisible" src="../../assets/images/menu_unfold.png" alt="" />
					<img v-if="menuVisible" src="../../assets/images/menu_close.png" alt="" />
				</div>
			</div>
		</div>
		<!-- 菜单 -->
		<div class="menu" :class="{ 'menu_visible': menuVisible }">
			<ul>
				<li v-for="(item, index) in menuItems" :key="index" :class="menuIndex == index?'active':''"
					@click="menuItemClick(item,index)">
					<span>{{ item.label }}</span>
					<img src="../../assets/images/arrow_right.png" alt="">
				</li>
			</ul>

			<div class="menu_btn">
				联系我们
			</div>

			<div class="menu_bottom">
				<div class="menu_row_text">
					<a href="tel:4000360170">联系电话：4000-360-170</a>
				</div>
				<div class="menu_row_text">
					周一至周五 9:00-12:30 14:00-18:00
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 控制菜单是否显示
				menuVisible: false,
				// 当前菜单索引
				menuIndex: 0,
				// 菜单列表
				menuItems: [{
						label: '首页',
						path: '/'
					},
					{
						label: '产品功能',
						path: '/products'
					},
					{
						label: '客户案例',
						path: '/cases'
					},
					{
						label: '立即试用',
						path: '/trial'
					},
					{
						label: '关于我们',
						path: '/about'
					},
					{
						label: '帮助中心',
						path: '/help'
					},
					// {
					// 	label: '新闻资讯',
					// 	path: '/news'
					// },
				],
			};
		},
		created() {
			this.menuIndexFun();
		},
		watch: {
			'$route.query.i'(newVal) {
				this.menuIndexFun();
			}
		},
		methods: {
			menuIndexFun() {
				const storedIndex = this.$route.query.i;
				if (storedIndex) {
					this.menuIndex = parseInt(storedIndex, 10);
				}
			},
			// 切换菜单显示状态
			toggleMenu() {
				this.menuVisible = !this.menuVisible;
				document.documentElement.style.overflow = this.menuVisible ? "hidden" : "visible";
			},
			// 菜单点击事件
			menuItemClick(item, index) {
				this.menuIndex = index
				this.toggleMenu();
				this.$router.push({
					path: item.path,
					query: {
						i: index
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		width: 100%;
		height: 1.49rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.44rem;
		box-sizing: border-box;
		background: #ffffff;
	}

	.header .logo {
		width: 3.4rem;
		height: 0.85rem;
	}

	.header .logo img {
		width: 100%;
		height: 100%;
	}

	.header .header_rt {
		display: flex;
		align-items: center;
	}

	.header .header_rt .rt_btn {
		width: 2.17rem;
		height: 0.88rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #FFFFFF;
		background: linear-gradient(90deg, #3E86F9 0%, #75AFFF 100%);
		border-radius: 0.13rem;
	}

	.header .header_rt .rt_menu_icon {
		margin-top: 0.08rem;
		margin-left: 0.35rem;
	}

	.header .header_rt .rt_menu_icon img {
		width: 0.73rem;
		height: 0.73rem;
	}

	.menu {
		width: 100%;
		height: calc(100vh - 1.49rem);
		position: fixed;
		top: 1.49rem;
		bottom: 0;
		right: -100%;
		background-color: #ffffff;
		transition: right 0.3s ease;
		overflow-y: auto;
		z-index: 999999;
	}

	.menu_visible {
		right: 0;
	}

	.menu ul li {
		width: 100%;
		height: 1.37rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 0.4rem;
		color: #333333;
		line-height: 1.37rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 0.91rem;
		padding-right: 0.91rem;
		background: #FFFFFF;
		border-bottom: 1px solid #EBEBEB;
	}

	.active {
		color: #3E86F9 !important;
	}

	.menu ul li:first-child {
		border-top: 1px solid #EBEBEB;
	}

	.menu ul li img {
		width: 0.48rem;
		height: 0.48rem;
	}

	.menu .menu_btn {
		width: 90%;
		height: 1.15rem;
		margin: 35% auto 0 auto;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 0.4rem;
		color: #FFFFFF;
		background: linear-gradient(90deg, #3E86F9 0%, #75AFFF 100%);
		border-radius: 0.13rem;
	}

	.menu .menu_bottom {
		width: 100%;
		margin: 1rem auto 0 auto;
	}

	.menu .menu_bottom .menu_row_text {
		text-align: center;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 0.32rem;
		color: #333333;
		line-height: 0.53rem;
	}
</style>