import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI);

// 全局默认样式
import '@/assets/styles/default.scss';

// rem适配方案
import "./utils/flexible";

// 分页组件
import Pagination from "@/components/Pagination";

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')