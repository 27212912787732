<template>
	<div class="mainContainer">
		<Headers :class="isScrolled ? 'fixed' : ''" />
		<router-view />
	</div>
</template>


<script>
	import Headers from "@/components/Headers";

	export default {
		components: {
			Headers,
		},
		data() {
			return {
				isScrolled: false,
				headerHeight: 0,
			};
		},
		mounted() {
			this.headerHeight = this.$el.querySelector(".header").offsetHeight;
			this.scrollHandler = this.checkScroll;
			window.addEventListener("scroll", this.scrollHandler);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.scrollHandler);
		},
		methods: {
			checkScroll() {
				const isBeyondHeader = window.scrollY > this.headerHeight;
				if (isBeyondHeader !== this.isScrolled) {
					this.isScrolled = isBeyondHeader;
				}
			},
		},
	};
</script>




<style lang="scss" scoped>
	.fixed {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		transition: top 0.3s ease, box-shadow 0.3s ease;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		z-index: 99999;
	}
</style>